export enum TopTagSort {
  MOST_USED = 'mostUsed',
  LAST_USED = 'lastUsed'
}

export enum TagType {
  PRESET_ONLY = 'presetOnly',
  ALL = 'all'
}

export enum TagViewLayout {
  LIST = 'list',
  COMPACT = 'compact'
}
