import { getLog } from '@aurora/shared-utils/log';
import dynamic from 'next/dynamic';
import React from 'react';
import { ItemType } from '../../../types/enums';
import type { ItemViewFCProps, ItemViewTypeAndProps, ItemViewVariant } from '../../entities/types';

const log = getLog(module);

const MessageView = dynamic(() => import('../../messages/MessageView/MessageView'));
const ModeratedMessageView = dynamic(
  () => import('../../messages/ModeratedMessageView/ModeratedMessageView')
);
// eslint-disable-next-line import/no-cycle
const NodeView = dynamic(() => import('../../nodes/NodeView/NodeView'));
const NotificationView = dynamic(
  () => import('../../notifications/NotificationView/NotificationView')
);
const PrivateConversationView = dynamic(
  () =>
    // eslint-disable-next-line import/no-cycle
    import('../../inbox/PrivateConversationView/PrivateConversationView')
);
const UserView = dynamic(() => import('../../users/UserView/UserView'));
const TagView = dynamic(() => import('../../tags/TagView/TagView'));
// eslint-disable-next-line import/no-cycle
const PrivateNoteView = dynamic(() => import('../../inbox/PrivateNoteView/PrivateNoteView'));
const AttachmentView = dynamic(() => import('../../attachments/AttachmentView/AttachmentView'));
const SubscriptionView = dynamic(
  () => import('../../subscriptions/SubscriptionView/SubscriptionView')
);
const MessageSearchView = dynamic(
  () => import('../../messages/MessageSearchView/MessageSearchView')
);
const UserInviteView = dynamic(() => import('../../users/UserInviteView/UserInviteView'));
const UserSearchView = dynamic(() => import('../../users/UserSearchView/UserSearchView'));
const AbuseReportedMessageView = dynamic(
  () => import('../../messages/AbuseReportedMessageView/AbuseReportedMessageView')
);
const AbuseReportedPrivateMessageView = dynamic(
  () => import('../../inbox/AbuseReportedPrivateMessageView/AbuseReportedPrivateMessageView')
);
const AbuseReportedUserView = dynamic(
  () => import('../../users/AbuseReportedUserView/AbuseReportedUserView')
);
const RejectedPrivateMessageView = dynamic(
  () => import('../../inbox/RejectedPrivateMessageView/RejectedPrivateMessageView')
);
const BanRuleView = dynamic(() => import('../../bans/BanRuleView/BanRuleView'));

const NodeSearchView = dynamic(() => import('../../nodes/NodeSearchView/NodeSearchView'));

const FilterEventsView = dynamic(
  () => import('../../filterEvents/FilterEventsView/FilterEventsView')
);
const BadgeView = dynamic(() => import('../../badges/BadgeView/BadgeView'));

const BadgeSetView = dynamic(() => import('../../badges/BadgeSetView/BadgeSetView'));

const IdeaStatusView = dynamic(() => import('../../ideas/IdeaStatusView/IdeaStatusView'));

const GuideView = dynamic(() => import('../../guides/GuideView/GuideView'));

const typeToViewMap: Record<ItemType, React.ComponentType> = {
  [ItemType.ATTACHMENT]: AttachmentView,
  [ItemType.BADGE]: BadgeView,
  [ItemType.BADGE_SET]: BadgeSetView,
  [ItemType.MESSAGE]: MessageView,
  [ItemType.MODERATED_MESSAGE]: ModeratedMessageView,
  [ItemType.NODE]: NodeView,
  [ItemType.NOTIFICATION]: NotificationView,
  [ItemType.PRIVATE_CONVERSATION]: PrivateConversationView,
  [ItemType.TAG]: TagView,
  [ItemType.PRIVATE_NOTE]: PrivateNoteView,
  [ItemType.SUBSCRIPTION]: SubscriptionView,
  [ItemType.USER]: UserView,
  [ItemType.MESSAGE_SEARCH]: MessageSearchView,
  [ItemType.USER_INVITE]: UserInviteView,
  [ItemType.ABUSE_REPORTED_MESSAGE]: AbuseReportedMessageView,
  [ItemType.ABUSE_REPORTED_PRIVATE_MESSAGE]: AbuseReportedPrivateMessageView,
  [ItemType.ABUSE_REPORTED_USER]: AbuseReportedUserView,
  [ItemType.REJECTED_PRIVATE_MESSAGE]: RejectedPrivateMessageView,
  [ItemType.NODE_SEARCH]: NodeSearchView,
  [ItemType.USER_SEARCH]: UserSearchView,
  [ItemType.BAN_RULE]: BanRuleView,
  [ItemType.FILTER_EVENTS]: FilterEventsView,
  [ItemType.IDEA_STATUS]: IdeaStatusView,
  [ItemType.GUIDE]: GuideView
};

/**
 * The view to use to view any item.
 * @author Manish Shrestha
 */
const ItemView = <
  EntityT,
  TypeT extends ItemType,
  VariantT extends ItemViewTypeAndProps<TypeT, ItemViewVariant<TypeT>>
>({
  entity,
  variant,
  type,
  className
}: ItemViewFCProps<EntityT, TypeT, VariantT> & {
  type: TypeT;
}): React.ReactElement => {
  const Component = typeToViewMap[type.toString()];

  if (Component) {
    return <Component entity={entity} variant={variant} className={className} />;
  }
  log.error('No item view for type %s', type);

  return null;
};

export default ItemView;
