export enum PanelType {
  STANDARD = 'standard',
  DIVIDER = 'divider',
  BUBBLE = 'bubble',
  SPACED = 'spaced',
  NONE = 'none'
}

export enum PanelSize {
  COMPACT = 'compact',
  STANDARD = 'standard'
}
